import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

const NutritionCol = ({ contentTop, contentBottom, displayItem }) => (
  <div className={`ratio ratio-1x1 rounded-3 border border-primary lp-nutrition-box lp-nutrition-box--${displayItem} me-12`}>
    <div className="d-flex flex-column justify-content-center align-items-center p-12 text-center">
      <div className='h6 fw-bold mb-0' dangerouslySetInnerHTML={{ __html: contentTop }}></div>
      {contentBottom && <div>{contentBottom}</div>}
    </div>
  </div>
);

const NutritionFact = ({ node }) => {
  let displayItem = null;
  let displayAmount = null;
  if (node.item) {
    if (node.item.includes('Protein')) {
      displayItem = 'protein';
      displayAmount = `${node.amount}<br />${displayItem}`;
    } else if (node.item.includes('Fiber')) {
      displayItem = 'fiber';
      displayAmount = `${node.amount}<br />${displayItem}`;
    }
  }
  if (displayItem) {
    return <NutritionCol contentTop={displayAmount} contentBottom={`${node.percentDailyValue}% DV`} displayItem={displayItem} />;
  }
  return null;
}

const CutOverviewLP = (props) => {
  const { node } = props;
  const firstRecipe = node.cutsFields.featuredRecipe[0];

  return (
    <Row key={`${node.slug}-overview`} className="py-2 py-md-3 py-xl-5 lp-cut-overview gx-0 gx-md-1 gx-lg-2" id="cuts">
      <Col xs="3" md="2" xxl="1" className="d-flex justify-content-center align-items-start lp-title-col">
        <h2 className="px-12 py-1 rounded-5 m-0 text-white" style={{ writingMode: 'vertical-rl', backgroundColor: node.cutsFields.accentColor, transform: 'rotate(180deg)'}}>
          {node.title}
        </h2>
      </Col>
      <Col xs="9" md={{span:5}} className="d-md-flex align-items-center align-items-md-start lp-cutting-board-col col-xxxl-4">
        <GatsbyImage
          image={node.cutsFields.cuttingBoardImage.localFile.childImageSharp.gatsbyImageData}
          alt={`Meati ${node.title}`}
          className="w-100 mb-1 lp-cutting-board-col__image"
        />
      </Col>
      <Col xs={{span: 9, offset: 3}} md={{span:5, offset:0, order: 1}} xxl="6" className="text-primary col-xxxl-7">
        <div className="mb-2 d-flex">
          {node.nutritionFacts.panelTop.map((item, index) => <NutritionFact key={index} node={item} />)}
          <NutritionCol contentTop="B vits + zinc + iron" />
        </div>
          <Row className="align-items-center gx-1 gx-sm-2">
            <Col xs="8" lg="9">
              <Link
                  to={`/recipes/${firstRecipe.slug}`}
                  title={firstRecipe.title}
                  data-ad-element-type="cta button"
                  data-ad-text={firstRecipe.title}
                  data-ad-button-id="version 1"
                  data-ad-button-location="body"
                >
                <h3 className='fw-bold h4'>{firstRecipe.title}</h3>
                <GatsbyImage
                  image={firstRecipe.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                  alt={`${firstRecipe.title}`}
                  className="w-100 rounded-4 mb-2"
                  imgClassName="rounded-4"
                />
              </Link>
              <Link
                className="btn btn-primary"
                data-ad-element-type="cta button"
                data-ad-text={`${node.title} Nutrition`}
                data-ad-button-id="version 1"
                data-ad-button-location="body"
                to={`/cuts/?nfp=${node.slug}`}
              >
                {node.title} Nutrition
              </Link>
            </Col>
            <Col xs="4" lg="3">
              <Link
                to={`/recipes/?cut=${node.slug}`}
                title="See All Recipes"
                className="btn btn-link text-primary d-flex flex-column align-items-start align-items-xl-center text-start text-xl-center p-0"
                data-ad-element-type="cta button"
                data-ad-text="See All Recipes"
                data-ad-button-id="version 1"
                data-ad-button-location="body"
              >
                <u>
                  See All Recipes
                </u>
                <FontAwesomeIcon aria-hidden="true" icon={faArrowRight} />
              </Link>
            </Col>
          </Row>
      </Col>
    </Row>
  )
}

export default CutOverviewLP
